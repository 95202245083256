import { MouseEvent, useCallback, useState } from 'react'
import css from './ProductCard.module.css'
import { Schemas } from '../../accessability'
import { composeClassName } from '../../util/composer'

/**
 * Properties for the ProductCard component
 *
 * @property {string} thumbnail - The URL of the product's thumbnail image
 * @property {string} name - The name of the product
 * @property {string} description - A short description of the product
 */
interface ProductCardProps {
    thumbnail: string
    name: string
    sku: string
}

/**
 * Displays a product card with a thumbnail, the product name, and a short description.
 * @param thumbnail - The URL to the thumbnail image.
 * @param name - The name of the product.
 * @param description - A short description of the product.
 * @constructor
 */
function ProductCard({thumbnail, name, sku}: ProductCardProps) {
    const [popoverExpanded, setPopoverExpanded] = useState(false)
    const openInEtsy = useCallback((ev: MouseEvent) => {
        window.open(`https://www.etsy.com/listing/${sku}/${name.toLowerCase().split(' ').join('-')}`)
        ev.preventDefault()
        ev.stopPropagation()
    }, [name, sku])

    return (
        <div
            className={`${css.ProductCard}`}
            itemScope
            itemType={Schemas.Product.schema}
            onMouseOver={() => setPopoverExpanded(true)}
            onMouseOut={() => setPopoverExpanded(false)}
            onClick={() => setPopoverExpanded(!popoverExpanded)}
        >
            <img
                src={thumbnail}
                itemProp={Schemas.Product.thumbnail}
                alt={name}
                className={css.ProductThumbnail}
            />
            <div className={composeClassName(css.CardPopover, popoverExpanded ? css.Expanded : css.Collapsed, 'vibrancy')}>
                <h4 itemProp={Schemas.Product.name}>{name}</h4>
                {popoverExpanded && <button className={css.ViewOnEtsy} onClick={openInEtsy}>View/Buy on Etsy</button>}
            </div>
        </div>
    )
}

export default ProductCard
export type { ProductCardProps }