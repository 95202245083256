import { Roles } from '../../accessability'
import { Brand } from '../../values'
import css from './Footer.module.css'

function Footer() {
    return (
        <footer className={css.Footer} role={Roles.Footer}>
            <div></div>
            <div>
                &copy; {new Date().getFullYear()} {Brand.maintainer}
            </div>
        </footer>
    )
}

export default Footer
