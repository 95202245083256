import { diamond, made_with_love, scarf, splash } from '../../assets'
import React from 'react'
import ParallaxContainer from '../ui/ParallaxContainer'
import css from './HomePage.module.css'
import { Roles } from '../../accessability'
import Button from '../ui/Button'

interface SectionThirdProps {
    icon: string
    title: string
    description: string
    color: string
}

function HomePage() {
    return (
        <div>
            <Hero />

            <div className={css.ThreeSection}>
                <SectionThird
                    icon={scarf}
                    title="Unique"
                    description="Each scarf is unique. No two scarfs will look similar or have the same design."
                    color="var(--accent-100)"
                />
                <SectionThird
                    icon={made_with_love}
                    title="Hand Made"
                    description="Each scarf is hand-made. From the design, to the colors, and paint."
                    color="var(--accent-300)"
                />
                <SectionThird
                    icon={diamond}
                    title="Premium"
                    description="We use the finest premium silk, dyes, and brushes, to ensure the best quality."
                    color="var(--accent-600)"
                />
            </div>

            <div className={css.Banner}>
                Looking for a unique, hand-made, premium silk scarf?
                <Button onClick={() => window.open('/store')}>Shop Now</Button>
            </div>
        </div>
    )
}

function Hero() {
    return (
        <ParallaxContainer background={splash} className={css.Hero}>
            <div className={css.HeroGradient}>
                <h1 role={Roles.Heading} aria-level={1}>
                    Beautiful. Hand-crafted. Scarves.
                </h1>

                <div className={css.HeroCTA}>
                    <Button
                        role={Roles.Link}
                        onClick={() => window.open('/store')}
                    >
                        Shop Now
                    </Button>
                    <Button className="ms-4" role={Roles.Link}>
                        Learn More
                    </Button>
                </div>
            </div>
        </ParallaxContainer>
    )
}

function SectionThird({ icon, title, description, color }: SectionThirdProps) {
    return (
        <div className={css.SectionThird} style={{ background: color }}>
            <img src={icon} alt={title} />
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
}

export default HomePage
