import { PropsWithChildren } from 'react'
import { Roles } from '../../accessability'
import { composeClassName } from '../../util/composer'
import css from './Button.module.css'

interface ButtonProps extends PropsWithChildren {
    role?: string
    className?: string
    onClick?: () => void
}

function Button({
    children,
    onClick,
    role = Roles.Button,
    className,
}: ButtonProps) {
    return (
        <button
            onClick={onClick}
            role={role}
            className={composeClassName(css.Button, className)}
        >
            {children}
        </button>
    )
}

export default Button
