import StoreLayout from '../layout/StoreLayout'
import { product_samples, products } from '../../assets'
import { ProductCardProps } from '../ui/ProductCard'

function StorePage() {
    const productList: ProductCardProps[] = product_samples.map(
        ({ name, thumbnails, sku }) => {
            return {
                name,
                sku,
                thumbnail: (products as Record<string, string>)[thumbnails[0]],
            }
        }
    )

    return <StoreLayout products={productList} />
}

export default StorePage
