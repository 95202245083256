import css from './StoreLayout.module.css'
import ProductCard, { ProductCardProps } from '../ui/ProductCard'

/**
 * Properties for the StoreLayout component
 *
 * @property {ProductCardProps[]} products - A list of the products to display
 */
interface StoreLayoutProps {
    products: ProductCardProps[]
}

/**
 * Displays a list of procucts, spaced evenly in a grid.
 * @param products - The list of products to display in the store
 * @constructor
 */
function StoreLayout({ products }: StoreLayoutProps) {
    return (
        <>
            <h1 className={css.Title}>Shop</h1>

            <div className={css.ProductCardGrid}>
                {products.map((product, i) => (
                    <ProductCard key={`${i}-${product.name}`} {...product} />
                ))}
            </div>
        </>
    )
}

export default StoreLayout
