import css from './AboutPage.module.css'
import { dreamz_icon } from '../../assets'

function AboutPage() {
    return (
        <div>
            <div className={css.CenteredTitle}>
                <div className={css.BlurContainer}>
                    <img
                        src={dreamz_icon}
                        alt="Dreamz Icon"
                        className={css.Icon}
                    />
                    <h1>About Dreamz by Sapna</h1>
                </div>
            </div>
        </div>
    )
}

export default AboutPage
