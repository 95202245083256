import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomePage from './components/pages/HomePage'
import NavigationBar from './components/layout/NavigationBar'
import StorePage from './components/pages/StorePage'
import Footer from './components/layout/Footer'
import AboutPage from './components/pages/AboutPage'

function App() {
    return (
        <BrowserRouter>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/store" element={<StorePage />} />
                <Route path="/about" element={<AboutPage />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default App
