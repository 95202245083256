import css from './NavigationBar.module.css'
import { composeClassName } from '../../util/composer'
import Button from '../ui/Button'
import { IonIcon } from '@ionic/react'
import { cart, menu } from 'ionicons/icons'
import { useState } from 'react'

/**
 * Navigation bar component that sticks to the top of the website.
 * @constructor
 */
function NavigationBar() {
    const [menuExpanded, setMenuExpanded] = useState(false)

    return (
        <div className={composeClassName(css.NavigationBar, 'vibrancy')}>
            <a href="/" className={css.Brand}>
                Dreamz
            </a>
            <nav
                className={composeClassName(
                    css.NavMenu,
                    menuExpanded ? css.Expanded : css.Collapsed
                )}
            >
                <a href="/" className={css.NavLink}>
                    Home
                </a>
                <a href="/store" className={css.NavLink}>
                    Store
                </a>
                <a href="/about" className={css.NavLink}>
                    About
                </a>
            </nav>

            <Button
                className={composeClassName(
                    css.ViewOnEtsy,
                    menuExpanded ? css.Expanded : css.Collapsed
                )}
                onClick={() => window.open('https://www.etsy.com/shop/SapDreamz', '_blank')}
            >
                <IonIcon icon={cart} />
                Etsy Store
            </Button>

            <Button
                className={css.ToggleMobileMenu}
                onClick={() => setMenuExpanded(!menuExpanded)}
            >
                <IonIcon icon={menu} />
            </Button>
        </div>
    )
}

export default NavigationBar
